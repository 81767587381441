/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import React, { useEffect, useState, useContext } from 'react';

import { ConfirmButton } from '~/components/Buttons';
import { ListBox } from '../ListBox';

import { ContainerLoader, Footer } from './styles';
import { IOrdemModuloProps } from './types';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';

const OrderTela: React.FC<IOrdemModuloProps> = ({
  setOpenOrderModal,
  openOrderModal,
  optionsBase,
  handleSaveOrder,
  label = 'Ordenação das Telas',
  loadModal = false,
}) => {
  const { colors } = useContext(ThemeContext);
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    setOptions(optionsBase);
  }, [optionsBase]);

  const handleCloseModal = () => {
    return setOpenOrderModal(false);
  };

  const Loading: React.FC = () => {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  };

  return (
    <>
      {openOrderModal && (
        <Modal width="large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>{label}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {loadModal ? (
              <div style={{ minHeight: '300px' }}>
                <Loading />
              </div>
            ) : (
              <ListBox
                optionAvailable={options}
                setOptionAvailable={setOptions}
                optionSelected={options}
                setOptionSelected={setOptions}
              />
            )}
            <Footer>
              <ConfirmButton
                onClick={() => setOpenOrderModal(false)}
                style={{
                  color: '#ffffff',
                  background: '#dc3545',
                  height: '100%',
                  maxHeight: '42px',
                }}
              >
                CANCELAR
              </ConfirmButton>
              <ConfirmButton
                onClick={() => handleSaveOrder(options)}
                style={{
                  color: '#ffffff',
                  background: '#28a745',
                  height: '100%',
                  maxHeight: '42px',
                }}
              >
                SALVAR
              </ConfirmButton>
            </Footer>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default OrderTela;
