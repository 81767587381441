import React, { useEffect, useState } from 'react';

import { IconeDinamico } from '../IconeDinamico';
import { useTrilha } from '../../TrilhaContext';
import { icons } from '~/pages/CadastroDeTela/data/Icons';
import { SelectProps } from '../../types';
import { InputSelectIconeDinamico } from '../InputSelect';

export const IconContainer: React.FC = () => {
  const { register, control, setValue, errors, watch, unregister } =
    useTrilha();

  const [dataIcons, setDataIcons] = useState<SelectProps[]>([]);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const watchLibIcon = watch('lib_icon');
  const des_icon = watch('des_icon');

  useEffect(() => {
    if (des_icon) {
      if (des_icon.value) {
        return setShowIcon(true);
      }
    }
    setShowIcon(false);
  }, [des_icon]);

  useEffect(() => {
    if (watchLibIcon) {
      if (watchLibIcon.value) {
        const filteredIcons = icons.find(
          (icon) => icon.lib === watchLibIcon.value,
        );

        if (filteredIcons) {
          const iconOptions = filteredIcons.values.map((icon) => ({
            icon: <IconeDinamico nameIcon={icon.label} />,
            label: icon.label,
            value: icon.value,
            lib: watchLibIcon.value,
          }));

          if (
            iconOptions.find((icon) => icon.value === des_icon.value) ===
            undefined
          ) {
            unregister('des_icon');
            setValue('des_icon', { value: undefined, label: '' });
          }

          setDataIcons(iconOptions);
        }
      } else {
        setDataIcons([]);
        unregister('des_icon');
        setValue('des_icon', { value: undefined, label: '' });
      }
    }
  }, [watchLibIcon]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <InputSelectIconeDinamico
        label="Icone"
        placeholder="- Selecione o ícone -"
        name="des_icon"
        register={register}
        isError={!!errors.des_icon}
        control={control}
        options={dataIcons}
        des_icon={des_icon ? des_icon.value : ''}
        showIcon={showIcon}
        changeSelected={(selected) => {
          setValue('des_icon', selected);
        }}
      />
    </div>
  );
};
