import React from 'react';
import { InputSelect } from '~/components/NovosInputs';

import { useTrilha } from '../../TrilhaContext';
import { libIcons } from '~/pages/CadastroDeTela/data/LibIcons';

export const LibsIconsContainer: React.FC = () => {
  const { register, control, errors, setValue, getValues } = useTrilha();

  return (
    <>
      <InputSelect
        label="Lib do ícone"
        placeholder="- Selecione a lib -"
        name="lib_icon"
        register={register}
        isError={!!errors.lib_icon}
        control={control}
        options={libIcons}
        changeSelected={(selected) => {
          const libIconVal = getValues('lib_icon');
          if (libIconVal.value !== selected.value) {
            setValue('lib_icon', selected);
          }
        }}
      />
    </>
  );
};
