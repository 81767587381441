import loadable from '@loadable/component';
import React, { useEffect } from 'react';
import { IconBaseProps } from 'react-icons/lib';

interface TypesPropsIcons {
  nameIcon: string;
  propsIcon?: IconBaseProps;
}

/**
 * @component
 * @name IconeDinamico
 * @description Componente responsável por renderizar os ícones selecionados de forma dinâmica.
 *
 * @example
 * // Exemplo de uso do componente
 * <IconeDinamico nameIcon={prop.name} />
 *
 * @param {string} nameIcon - Label do ícone.
 * @param {IconBaseProps|undefined} [propsIcon=undefined] - Propriedades do ícone.
 */

export const IconeDinamico: React.FC<TypesPropsIcons> = ({
  nameIcon,
  propsIcon,
}): JSX.Element => {
  const libraryByIconName =
    nameIcon?.slice(0, 2).toLowerCase() === 'vs'
      ? 'vsc'
      : nameIcon?.slice(0, 2).toLowerCase();

  const lib = libraryByIconName;

  if (!lib) return <></>;

  const ElementIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: (el: JSX.Element) => el[nameIcon as keyof JSX.Element],
    fallback: <small>Buscando...</small>,
  });

  return <ElementIcon {...propsIcon} />;
};
