import * as yup from 'yup';

export const schema = yup.object({
  des_trilha: yup.string().required(),

  lib_icon: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  des_icon: yup.object().shape({
    value: yup.string().required(),
    label: yup.string().required(),
  }),
});
