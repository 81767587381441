import React from 'react';

import { TrilhaContextProvider } from './TrilhaContext';
import { TrilhaContent } from './TrilhaContent';

export const Trilha: React.FC = () => {
  return (
    <TrilhaContextProvider>
      <TrilhaContent />
    </TrilhaContextProvider>
  );
};
