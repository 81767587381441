import styled from 'styled-components';

export const Footer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  input {
    border: 1px solid #dfe1e6;
    padding: 5px;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    border-radius: 3px;
  }
`;

export const ContainerLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
