import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  // Customização do DataGrid
  .myDataGrid .hideRightSeparator .MuiDataGrid-columnSeparator {
    display: none;
  }

  .dataTable {
    max-height: 400px !important;
    width: 100% !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .MuiDataGrid-windowContainer {
    max-height: 450px !important;
    min-height: 400px !important;

    .MuiDataGrid-window {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }

  .MuiDataGrid-renderingZone {
    max-height: 400px !important;
  }
  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
    background-color: transparent !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    right: -20px !important;
  }
  .MuiIconButton-label {
    display: none !important;
  }

  .MuiDataGrid-footerContainer {
    display: none !important;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const ButtonReturnCards = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  min-width: 150px;
  background-color: #ffc107;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  animation: all 0.25s ease-in;
  &.addNew {
    background-color: #007bff;
    &.span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.ordenar {
    background-color: rgb(51, 185, 21);
    &.span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:disabled {
    background-color: #f1e1b0;
    cursor: not-allowed;
    &:hover {
      background-color: #f1e1b0;
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const ContainerOrder = styled.div`
  margin-left: 30px;
  @media (min-width: 500px) {
    margin-right: 10px;
  }
`;
